import React, {useState} from "react"
import { Link } from "gatsby"
import PropTypes ,  { bool } from "prop-types"
// import "./header.scss"
import { StyleHeader } from './header.styled';
import { StyledBurger } from "./Burger.styled";
import Fade from 'react-reveal/Fade';


function Header({ siteTitle, menuLinks, open }){

    return(
        <StyleHeader open={open}>
        {menuLinks.map(link => (
        <a key={link.name} className="menu" >
            <Link activeStyle={{ color: "#89cff0" }}
                partiallyActive={true} href={link.Link}>
            <br/>
            {link.name}
            </Link >
        </a>
        )
        
      )}
        </StyleHeader>
    )
}

Header.prototype = {
    siteTitle: PropTypes.string,
    open: bool.isRequired
}

Header.defaultProps = {
    siteTitle: ``
}
export default Header