import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
// import "./header.scss"
import Fade from 'react-reveal/Fade';


function Footer(){
    return(
        <div className="footer-intro">
        </div>
    )
}

export default Footer