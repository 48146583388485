import React, {useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery,graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import "./layout.scss"
import { Grid, Box} from 'theme-ui'
import Burger from "./burger"


const Layout = ( { children }) => {
    
    const [open, setOpen] = useState(false);

    const data = useStaticQuery(graphql`
        query SiteTitleQuery{
            site {
                siteMetadata {
                    title
                    menuLinks {
                        name
                        Link
                    }
                }
            }
        }
    `)

    return(
        <div>
        <Burger className="burger" open={open} setOpen={setOpen}  />
        <Header open={open} setOpen={setOpen} menuLinks={data.site.siteMetadata.menuLinks} 
        siteTitle={data.site.siteMetadata.title}/>
        <div className="layout" >
                <h2 className="banner">geosemiotics.net</h2>
                <main  as='main' className="main">{children}</main>

            <div>
                <Footer className="footer"></Footer>
            </div>
          
        </div>
        </div>
    )
}

Layout.prototype = {
    children: PropTypes.node.isRequired

}
export default Layout 